import React from 'react';
import LiveDate from './LiveDate';

function TopMenu() {
  return (
    <div>
       <header id="page-topbar">
                <div class="navbar-header">
                    <div class="d-flex">
                          
                        <div class="navbar-brand-box">
                            <a href="#" class="logo logo-dark">
                                <span class="logo-sm">
                                AllDay
                                </span>
                                <span style={{textDecoration:"none", color:"black",fontSize:"17px",fontWeight:"bolder"}} class="logo-lg">
                                AllDay Bread
                                </span>
                            </a>

                            <a href="#" style={{textDecoration:"none", color:"black",fontSize:"17px",fontWeight:"bolder"}} class="logo logo-light">
                               AllDay Bread
                            </a>
                        </div>

                        <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn" id="vertical-menu-btn">
                            <i class="ri-menu-2-line align-middle"></i>
                        </button>
            
                    
                      <div class="page-title-box align-self-center d-none d-md-block">
                        <h4 class="page-title mb-0">AllDay Bread</h4>
                      </div>
                     
                    </div>

                    <div class="d-flex">

                         
                         <form class="app-search d-none d-lg-block">
                            <div class="position-relative">
                                <input type="text" class="form-control" placeholder="Search..."/>
                                <span class="ri-search-line"></span>
                            </div>
                        </form>

                        {/* <div class="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="ri-search-line"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">
                    
                                <form class="p-3">
                                    <div class="mb-3 m-0">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search ..."/>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}

                        {/* <div class="dropdown d-none d-sm-inline-block">
                            <button type="button" class="btn header-item waves-effect"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img class="" src="assets/images/flags/us.jpg" alt="Header Language" height="16"/>
                            </button>
                            
                        </div> */}

                        

                        <div class="dropdown d-none d-lg-inline-block ms-1">
                           <LiveDate/>
                        </div>

                      
            
                    </div>
                </div>
            </header>
    </div>
  );
}

export default TopMenu;
