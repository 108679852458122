import React, { useEffect, useState } from 'react';
import bread from './bread.jpg'
import { DoLogin, DConnect } from './components/Config'
function Login() {
    const [formitems, setformitems] = useState({ username: "", password: "" })

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setformitems(prevState => ({ ...prevState, [name]: value }));
    }
    const Dologin = (e) => {
        e.preventDefault();
        DoLogin(formitems)
    }


  return (
    <div>
         <div class="auth-maintenance d-flex align-items-center min-vh-100">
            <div class="bg-overlay bg-light"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                            <div class="w-100">
                                <div class="d-flex flex-column h-100 py-0 py-xl-3">
                                    <div class="text-center mb-4">
                                        <a href="index.html" class="">
                                            <h3>All Day Bread</h3>
                                        </a>
                                        <p class="text-muted mt-2">All Day Bread, the soul of every meal</p>
                                    </div>
    
                                    <div class="card my-auto overflow-hidden">
                                            <div class="row g-0">
                                                <div class="col-lg-6">
                                                    <div class="bg-overlays bg-primary"></div>
                                                    <div class="h-100  align-items-end">
                                                        <img src={bread} width={"100%"} height={"100%"}/>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="p-lg-5 p-4">
                                                        <div>
                                                            <div class="text-center mt-1">
                                                                <h4 class="font-size-18">Welcome Back!</h4>
                                                                <p class="text-muted">Sign in to continue.</p>
                                                            </div>
            
                                                            <form action="#" onSubmit={Dologin} class="auth-input">
                                                                <div class="mb-2">
                                                                    <label for="username" class="form-label">Username</label>
                                                                    <input type="text" class="form-control" name="username" id="username" value={formitems.username} onChange={handleChange}  placeholder="Enter username"/>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label" for="password-input">Password</label>
                                                                    <input type="password" class="form-control" name="password" value={formitems.password} onChange={handleChange} placeholder="Enter password"/>
                                                                </div>
                                                                <div class="form-check">
                                                                    {/* <input class="form-check-input" type="checkbox" value="" id="auth-remember-check"/>
                                                                    <label class="form-check-label" for="auth-remember-check">Remember me</label> */}
                                                                </div>
                                                                <div class="mt-3">
                                                                    <button class="btn btn-primary w-100" type="submit">Sign In</button>
                                                                </div>
                                                                <div class="mt-4 pt-2 text-center">
                                                                    {/* <div class="signin-other-title">
                                                                        <h5 class="font-size-14 mb-4 title">Sign In with</h5>
                                                                    </div> */}
                                                                    {/* <div class="pt-2 hstack gap-2 justify-content-center">
                                                                        <button type="button" class="btn btn-primary btn-sm"><i class="ri-facebook-fill font-size-16"></i></button>
                                                                        <button type="button" class="btn btn-danger btn-sm"><i class="ri-google-fill font-size-16"></i></button>
                                                                        <button type="button" class="btn btn-dark btn-sm"><i class="ri-github-fill font-size-16"></i></button>
                                                                        <button type="button" class="btn btn-info btn-sm"><i class="ri-twitter-fill font-size-16"></i></button>
                                                                    </div> */}
                                                                </div>
                                                            </form>
                                                        </div>
                                                    
                                                        {/* <div class="mt-4 text-center">
                                                            <p class="mb-0">Don't have an account ? <a href="auth-register.html" class="fw-medium text-primary"> Register </a> </p>
                                                        </div> */}
                                                    </div>
                                                </div>  
                                        </div>
                                    </div>
                             
                                    
                                    <div class="mt-5 text-center">
                                        <p class="mb-0">©{new Date().getFullYear()} Design <i class="mdi mdi-heart text-danger"></i> by Tarixel Technology</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Login