
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import base64 from "base-64";
import { ModalContent, ModalFooter, ModalButton, useDialog, Confirm, Alert } from 'react-st-modal';
 import { NotificationManager } from 'react-notifications';


const DoLogin = (user) => {
    //alert("is")
    Axios(PostOptions('POST', user, `users/login`)).then(res => {
        let respond = res.data
        //alert(JSON.stringify(respond))
        if(respond){
            getRoles(user)
           // setUserTokenKey(user.password)
        }else{
            alert("Invalid Credentials")
            NotificationManager.error("Invalid Credentials", 'Error!', 4000);
        }
    })//.catch(function (error) { alert(error) });
}


const InnerModal = ({children, ...props}) => {
    const dialog = useDialog();

    return <div>
            <ModalContent> {children} </ModalContent>
            <ModalFooter>
                {props.btns.map( (b, idx) =>
                    <ModalButton className={`btn-${b.color}`} style={{padding:'5px 15px'}} onClick={() => { dialog.close(idx); }} >{b.label}</ModalButton>
                )}
            </ModalFooter>
        </div>
};



const getRoles = (respond) => {
    //alert(JSON.stringify("is here"))
    DConnect().get(`users/getusernameemail/${respond.username}`).then(res => {
        let user = res.data
        DConnect().get(`users/fetchroleid/${user.role}`).then(res => {
           
            let features = res.data
          //  alert(JSON.stringify(features.features))
            setFeatures(features.features)
            setUser(user)
            setUsername(user.username)
            window.location.replace('/#/dashboard');
            window.location.reload(true);
            // window.location.replace('/#/dashboard');
            // window.location.reload(true);
        })
    })
}

const DConnect = () => {
    return Axios.create({
        baseURL: BaseURL() ,
        timeout: 60000,
        headers: {
            // 'Content-Type': 'application/json',
            
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': '*'
        }
    });
}
const PostOptions = (meth, formBody, area) => {
    return {
        method: meth,
        url: BaseURL()  + area,
        data: formBody,
        headers: {
            // 'Content-Type': 'application/json',
           
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': '*'
        }
    }
}
const PostOptionsImage = (meth, formBody, area) => {
    return {
        method: meth,
        url: BaseURL() + area,
        data: formBody,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': ` Bearer ${getToken()}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*'
        }
    }
}

//headers: { 'Content-Type': 'multipart/form-data' },

const BaseURL = () => {
   return 'https://alldaybread.tarixel.com/breadapi/';
   //return 'http://localhost/breadapi/';
 }

 const Logout = () => {
    sessionStorage.removeItem('bread_token');
    sessionStorage.removeItem('bread_user');
    sessionStorage.removeItem('bread_features');
    sessionStorage.removeItem('bread_tokens');
    sessionStorage.removeItem('bread_client');
    window.location.replace('/login');
    window.location.reload(true);
}

const setUser = (user) => sessionStorage.setItem('bread_user', JSON.stringify(user));
const getUser = () => JSON.parse(sessionStorage.getItem('bread_user'));

const getAuthorization = () => (getUser() == null) ?
    { 'username': '', 'token': '' } :
    { 'username': getUser().username, 'token': getUser().token };

const setFeatures = (userole) => sessionStorage.setItem('features', JSON.stringify(userole));
const getFeatures = () => JSON.parse(sessionStorage.getItem('features'));

const setUsername = (userole) => sessionStorage.setItem('bread_username', JSON.stringify(userole));
const getUsername = () => JSON.parse(sessionStorage.getItem('bread_username'));

const LoggedIn = () => getUser() !== null;
const IsAuthenticated = () => LoggedIn();
const setToken = (token) => sessionStorage.setItem('bread_token', token);
const getToken = () => sessionStorage.getItem('bread_token');

const Config=()=>{

}

 Config.DoLogin = DoLogin
 Config.InnerModal = InnerModal;
Config.BaseURL = BaseURL
Config.PostOptions = PostOptions
Config.setToken = setToken
Config.getToken = getToken
Config.PostOptionsImage=PostOptionsImage
Config.getAuthorization = getAuthorization
Config.IsAuthenticated = IsAuthenticated
Config.setFeatures = setFeatures
Config.getFeatures = getFeatures
Config.Logout = Logout
Config.setUser = setUser
Config.getUser = getUser
Config.DConnect=DConnect
Config.getUsername=getUsername
export default Config;
export {
    InnerModal,BaseURL,PostOptionsImage,PostOptions,setToken,getUsername, getToken, getAuthorization, IsAuthenticated, setFeatures, getFeatures, Logout, getUser, setUser,DConnect,DoLogin
}