import React from "react";
import App from "../App";
const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

 const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
// const Transactions = React.lazy(() => import('../views/transactions/Transactions'));

const Roles= React.lazy(() => import('../views/Profile/Role'));
const Users = React.lazy(() => import('../views/Profile/User'));
const CompanyCon = React.lazy(() => import('../views/Production/ProductionCon'));
const Categories = React.lazy(() => import('../views/Category/CategoriesCon'));
const Products = React.lazy(() => import('../views/Products/ProductCon'));
const Product = React.lazy(() => import('../views/Products/ProductCons'));
const Managerpos = React.lazy(() => import('../views/Sales/Managerpos'));
const Team = React.lazy(() => import('../views/Team/TeamCon'));
const DistributionCon = React.lazy(() => import('../views/Distribution/DistributionCon'));
const SalesCon = React.lazy(() => import('../views/Reports/SalesCon'));
const ProductionReport = React.lazy(() => import('../views/Reports/ProductionCon'));
const ReturnCon = React.lazy(() => import('../views/Reports/ReturnCon'));
const WeeklyReport = React.lazy(() => import('../views/Reports/WeeklyReport'));

const Expenses = React.lazy(() => import('../views/Expenses/ExpensesCon'));
const Attendance = React.lazy(() => import('../views/Attendance/AttendanceCon'));

const Misc = React.lazy(() => import('../views/Profile/Misc'));

const routes = [
     { path: "/dashboard", name: "Dashboard", component: Dashboard },
     
    //  { path: "/order", name: "Products", component: Transactions },
    //  { path: "/attendance", name: "Attendance", component: Attendance },
      { path: "/misc", name: "Accounts", component: Misc },
    { path: "/attendance", name: "Order", component: Attendance },
     { path: "/expenses", name: "Expenses", component: Expenses },
     { path: "/returnsreport", name: "Scrap Mail", component: ReturnCon },
    //  //bank
    { path: "/productionreport", name: "Production", component: ProductionReport },
     { path: "/salereport", name: "Sale Report", component: SalesCon },
     { path: "/weeklyreport", name: "Weekly Report", component: WeeklyReport },

     { path: "/product", name: "Products", component: Product },
      { path: "/distribution", name: "Distribution", component: DistributionCon },
     { path: "/team", name: "Team", component: Team },
     { path: "/pos", name: "Auto Script", component: Managerpos },
     { path: "/stocks", name: "Stocks", component: Products },
      { path: "/production", name: "Production", component: CompanyCon },
     { path: "/role", name: "Role", component: Roles },
     { path: "/users", name: "Users", component: Users },
    // { path: "/changepassword", name: "Change Password", component: ChangePassword }


]

export default routes;