import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

export default function MenuList(){
    const [isSuper, setisSuper] = useState(false)

    useEffect(() => {
    //    if(getUser() ==null){
    //     setisSuper(true)
       
    //    }
    }, [])

    var menu  = [
            {code:'1',role:"Dashboard",src:"dashboard",icon:<Icon icon="uim:airplay" />,name:"Dashboard" ,link:"dashboard",
            submenu: []},
            {code:'9',role:"POS", src:"pos", name:'POS',  icon:<Icon icon="mdi:printer-pos-outline" />,link:"pos",
            submenu:[]
            },
            {code:'7',role:"Distribution", src:"distribution", name:'Distribution',  icon:<Icon icon="uil:vertical-distribution-top" />,link:"distribution",
            submenu:[]
            },
            {code:'10',role:"Products", src:"product", name:'Product',  icon:<Icon icon="tabler:home-dollar" />,link:"product",
            submenu:[]
            },
           
            {code:'2',role:" Production", src:"production", name:' Production',  icon:<Icon icon="mdi:users" />,link:"production",
            submenu:[]
            },

            {code:'3',role:"Team", src:"team", name:'Team',  icon:<Icon icon="uim:window-grid" />,link:"team",
            submenu:[]
            },
            {code:'4',role:"Expenses", src:"expenses", name:'Expenses',  icon:<Icon icon="mdi:users" />,link:"expenses",
            submenu:[]
            },
            {code:'5',role:"Attendance", src:"attendance", name:'Attendance',  icon:<Icon icon="mdi:users" />,link:"attendance",
            submenu:[]
            },
           
            {code:'6',role:"Report", src:"bank", name:'Report',  icon:<Icon icon="entypo:v-card" />,link:"report",
            submenu:[
                {code:'61',role:"Sales Report",src:"Sales",link:"salereport", name:"Sales Report"},
                {code:'62',role:"Production Report",src:"production",link:"productionreport", name:"Production Report"},
                {code:'63',role:"Returns Report",src:"returns",link:"returnsreport", name:"Returns Report"},
                 {code:'64',role:"Weekly Report",src:"weekly",link:"weeklyreport", name:"Weekly Report"},
            ]
            },
            
            {code:'8',role:"Settings", src:"Settings", name:'Settings',  icon:<Icon icon="icon-park-solid:setting-computer" />,link:"settings",
            submenu:[
                {code:'81',role:"Role",src:"Role",link:"role", name:"Role"},
                {code:'82',role:"Users",src:"Users",link:"users", name:"Users"},
                {code:'83',role:"Misc",src:"Misc",link:"misc", name:"Misc"},
                // {code:'34',role:"Attendance",src:"Attendance",link:"attendance", name:"Attendance"}
            ]
            }
        ]
   
    

    return menu;
}