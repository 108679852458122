import React, { Suspense, Fragment, useState, useEffect } from 'react';
import { HashRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';
import routes from './routes';

function DefaultLayout() {

    const loader = () => <div id="loader"></div>;
    return (
        <Suspense fallback={loader}>
            <HashRouter>
                <div id="layout-wrapper">
                    <TopMenu />
                    <SideMenu />
                    <Switch>
                    <div class="main-content">

                        <div class="page-content">
                            <div class="container-fluid">
                                {routes.map((route, idx) => {
                                    return route.component ? (
                                        <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                            <route.component {...props} />
                                        )} />
                                    ) : (null);
                                })}
                                <Redirect from="/" to="/dashboard" />
                            </div>
                        </div>
                    </div>
                    </Switch>
                </div>
            </HashRouter>
        </Suspense>
    );
}

export default DefaultLayout;
