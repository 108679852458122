import React, { Fragment, useState, useEffect } from 'react';
import $ from 'jquery'
import MenuList from './MenuList'
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Logout, getFeatures, setFeatures } from './Config';

function SideMenu() {
    const [menulist, setmenulist] = useState(new MenuList())
    
    useEffect(() => {
        //alert(JSON.stringify(getFeatures()))
        if (getFeatures() == null) {
          Logout()
        } else {
           // alert(JSON.stringify(getFeatures()))
          // setFeatures(JSON.parse(getFeatures()))
         // let code=JSON.parse(getFeatures())
          //alert(JSON.stringify(getFeatures))
         }

        $('ul#side-menu').on('click', 'li', (ex) => {
            $('ul#side-menu li ul li').on('click', (e) => { });
            var id = $(ex.currentTarget).attr('id')
            if (!$(ex.currentTarget).hasClass('submenu')) {
                $('ul#side-menu li').removeClass('active');
                $(ex.currentTarget).addClass('active');
                // $('ul#side-menu li ul').slideUp();
                if($(ex.currentTarget).children('ul').is(":visible")){
                    $('ul#side-menu li ul').slideUp();
                }else{
                    $(ex.currentTarget).children('ul').slideDown();
                }
                
                // $('ul#side-menu li').removeClass('first-item');


                // $('ul#side-menu li ul').addClass('collapse');
                // $(ex.currentTarget).addClass('first-item');
               
                // $(ex.currentTarget).children('ul').removeClass('collapse');
            } else if ($(ex.currentTarget).hasClass('submenu')) { //Submenu clicked

                $('ul#side-menu li ul#').slideDown(350);
                $('ul#side-menu li').removeClass('first-item');
                $('ul#side-menu li').removeClass('active');
                $('ul#side-menu li ul').addClass('collapse');

                $(ex.currentTarget).addClass('first-item');
                $(ex.currentTarget).addClass('active');
                $('#' + id).slideDown(350);
               
            } else if ($(ex.currentTarget).children('a').children('span').hasClass('link-title') && //Single menu clicked
                !$(ex.currentTarget).children('a').children('span').hasClass('arrow')) {
                $('ul#side-menu li ul').slideUp();
                $('ul#side-menu li').removeClass('first-item');
                $('ul#side-menu li').removeClass('active');
                $('ul#side-menu li ul').addClass('collapse');
                $(ex.currentTarget).addClass('first-item');
                $(ex.currentTarget).addClass('active');
            }
        });
    }, [])

    let feat=JSON.parse(getFeatures())
    return (
        <div>
            <div class="vertical-menu">


                <div class="navbar-brand-box">
                    <a href="#" style={{textDecoration:"none", color:"white",fontSize:"17px",fontWeight:"bolder"}} class="logo logo-dark">
                       AllDay Bread
                    </a>

                    <a href="#" style={{textDecoration:"none", color:"white",fontSize:"17px",fontWeight:"bolder"}} class="logo logo-light">
                    AllDay Bread
                       
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn" id="vertical-menu-btn">

                    <Icon icon="jam:menu" />
                </button>

                <div data-simplebar class="vertical-scroll">
                    <div id="sidebar-menu">

                        <ul class="metismenu list-unstyled" id="side-menu">
                            <li class="menu-title">Menu</li>
                            {/* <p>{JSON.stringify(feat)}</p> */}
                            {menulist.map(mn => (
                                feat[mn.code] !== undefined &&
                                <>
                                    <li id={(mn.submenu != '') ? "sub" + mn.code : "mun" + mn.code} class={(mn.submenu != '') ? "submenu" + "sub" + mn.code : (mn.code == 1) ? "active" : ""}>
                                        {(mn.submenu == '') ?
                                            <Link to={mn.link}>
                                               {mn.icon}<span class="shape1"></span><span class="shape2"></span><span>{mn.name}</span>
                                            </Link>
                                            :
                                           feat[mn.code] !== undefined &&
                                            <a href="javascript:void(0);"  class="has-arrow">{mn.icon} <span>{mn.name}</span> </a>

                                        }

                                        {mn.submenu != '' &&
                                            <ul class="sub-menu" aria-expanded="false"  style={{ display: "none" }}>

                                                {mn.submenu.map(smenu => (feat[mn.code].includes(smenu.code) &&
                                                    <li >
                                                        <Link to={smenu.link} >
                                                            <span style={{ color: "white" }}>{smenu.name} </span>
                                                        </Link>

                                                    </li>
                                                )
                                                )}
                                            </ul>
                                        }
                                    </li>
                                </>
                            ))}

                        </ul>

                    </div>
                </div>

                <div class="dropdown px-3 sidebar-user sidebar-user-info">
                    <button type="button" class="btn w-100 px-0 border-0" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                                {/* <img src="assets/images/users/avatar-2.jpg" class="img-fluid header-profile-user rounded-circle" alt="" /> */}
                                <i className='fa fa-power-off fa-2x'></i>
                            </div>

                            <div class="flex-grow-1 ms-2 text-start">
                                <span class="ms-1 fw-medium user-name-text">Logged In</span>
                            </div>

                            <div class="flex-shrink-0 text-end">
                                <i class="mdi mdi-dots-vertical font-size-16"></i>
                            </div>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">

                        {/* <a class="dropdown-item" href="pages-profile.html"><i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Profile</span></a>
                        <a class="dropdown-item" href="apps-chat.html"><i class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Messages</span></a>
                        <a class="dropdown-item" href="pages-faq.html"><i class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Help</span></a>
                        <div class="dropdown-divider"></div> */}
                    
                       <a class="dropdown-item" href="#"><i ></i> <span class="fa fa-lock"> Lock screen</span></a>
                       <a class="dropdown-item" href="#" onClick={()=>Logout()}><i class=""></i> <span class="fa fa-power-off"> Logout</span></a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SideMenu;
